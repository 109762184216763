import { FC } from "react"

export const TermsAndPolicy: FC = () => {
    return (
        <>
            <p className="text-size-regular text-align-center text-lineheight-normal">
              By creating an account, you agree to our{' '}
              <a
                href="https://www.trend.io/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href="https://www.trend.io/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </p>
            <p className="text-size-regular text-align-center text-lineheight-normal"></p>
        </>
    )
}