// AuthForm
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import styles from './AuthForm.module.sass';
import Input from 'components/common/Input';
import { getLocalImageUrl } from 'utils/image';
import { GoogleSSOauth } from '../GoogleSSOauth';
import { TermsAndPolicy } from '../TermsAndPolicy';
import { AuthFormState, AuthFormType, LinkAuthFormType } from 'types/auth';
import { passwordResetEmail } from 'services/firebaseAuth';
import { validateEmail, validatePasswordOrReturnError } from 'utils/validators';
import { useAuth } from 'hooks/useAuth';
import Loader from 'components/common/Loader';
import { AUTH_FORM_COPY } from 'constants/auth';

export interface AuthFormProps {
  authForm: AuthFormState;
  authFormType: AuthFormType;
  setAuthForm: React.Dispatch<React.SetStateAction<AuthFormState>>;
  setAuthFormType:
    | React.Dispatch<React.SetStateAction<AuthFormType>>
    | React.Dispatch<React.SetStateAction<LinkAuthFormType>>;
  onSignUp: () => void;
  onLogin: () => void;
  onGoogleSSO: () => void;
}

const AuthForm: React.FC<AuthFormProps> = ({
  authForm: { email, password },
  authFormType,
  setAuthForm,
  setAuthFormType,
  onSignUp,
  onLogin,
  onGoogleSSO,
}) => {
  const {
    authState: { status: authStatus, error: authError },
  } = useAuth();
  const [emailError, setEmailError] = useState<string | undefined>();
  const [passwordError, setPasswordError] = useState<string | undefined>();
  const [emailSendNote, setEmailSendNote] = useState<string | void>('');

  useEffect(() => {
    if (authError) {
      const emailErrorRegex = /(user)|(email)/gi;
      if (emailErrorRegex.test(authError)) {
        setEmailError(authError);
      } else {
        setPasswordError(authError);
      }
    }
  }, [authError]);

  const handleEmailAuth = async () => {
    if (authFormType === 'forgot-password') {
      // NOTE: Is this the right password reset for soona?
      setEmailSendNote(await passwordResetEmail(email));
    } else if (authFormType === 'sign-up') {
      handleSignUp();
    } else {
      handleLogin();
    }
  };

  const onInputChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { id, value },
  }) => {
    setAuthForm((form) => ({ ...form, [id]: value }));
    if (id === 'email') {
      setEmailError(undefined);
    } else if (id === 'password') {
      setPasswordError(undefined);
    }
  };

  const toggleFormType = () => {
    setAuthFormType((type: AuthFormType | LinkAuthFormType) =>
      type === 'sign-up' ? 'login' : 'sign-up'
    );
  };

  const handleLogin = () => {
    if (checkErrors()) {
      onLogin();
    }
  };

  const handleSignUp = () => {
    if (checkErrors()) {
      onSignUp();
    }
  };
  const handleGoogleSSO = () => {
    onGoogleSSO();
  };
  const handleForgotPassword = () => setAuthFormType('forgot-password');

  const checkErrors = () => {
    const isValidEmail = validateEmail(email);
    setEmailError(isValidEmail ? undefined : 'Invalid email');
    const isValidPasswordOrError = validatePasswordOrReturnError(password);
    setPasswordError(
      typeof isValidPasswordOrError === 'string'
        ? isValidPasswordOrError
        : undefined
    );
    return isValidEmail && typeof isValidPasswordOrError !== 'string';
  };

  const handleEmailKeyUp: React.KeyboardEventHandler<HTMLInputElement> = ({
    key,
  }) => {
    if (key === 'Enter' || key === 'Tab') {
      document.getElementById('password')?.focus();
    }
  };

  const handlePasswordKeyUp: React.KeyboardEventHandler<HTMLInputElement> = ({
    key,
  }) => {
    if (key === 'Enter') {
      if (authFormType === 'sign-up') {
        handleSignUp();
      } else {
        handleLogin();
      }
    }
  };

  return (
    <div className={styles.authForm}>
      <div className={styles.titleLogo}>
        <img
          src={getLocalImageUrl('/images/trend-logo-black.svg')}
          alt="trend-logo"
        />
      </div>
      <h3>
        {emailSendNote ? emailSendNote : AUTH_FORM_COPY[authFormType].title}
        {authFormType === 'login' && (
          <>
            <br />
            Log Into Trend
          </>
        )}
      </h3>
      <p className="text-size-regular">
        {AUTH_FORM_COPY[authFormType].subtitle}
      </p>
      <div className={styles.inputContainer}>
        {authStatus === 'loading' || authStatus === 'authenticated' ? (
          <div className={styles.loadingContainer}>
            <Loader />
          </div>
        ) : (
          <>
            <GoogleSSOauth
              isGoogleAuth={authFormType !== 'forgot-password'}
              handleGoogleSSO={handleGoogleSSO}
              styles={styles}
            />
            <Input
              type="email"
              id="email"
              placeholder={
                authFormType === 'login' ? 'Login Email' : 'Company Email'
              }
              wrapperClassName={styles.inputWrapper}
              value={email}
              onChange={onInputChange}
              error={emailError}
              onKeyUp={handleEmailKeyUp}
              showErrorText
            />
            {authFormType !== 'forgot-password' && (
              <Input
                type="password"
                id="password"
                placeholder="Password"
                wrapperClassName={styles.inputWrapper}
                value={password}
                onChange={onInputChange}
                onKeyUp={handlePasswordKeyUp}
                showErrorText
                error={passwordError}
              />
            )}
            {authFormType === 'login' && (
              <p
                onClick={handleForgotPassword}
                className={styles.forgotPassword}
              >
                Forgot Password?
              </p>
            )}
            <button
              className="button-3d full-width"
              onClick={handleEmailAuth}
              disabled={
                authFormType !== 'forgot-password'
                  ? Boolean(!email || !password || emailError || passwordError)
                  : Boolean(!email)
              }
            >
              {AUTH_FORM_COPY[authFormType].actionButtonText}
            </button>
          </>
        )}
      </div>
      <div className={styles.footerContent}>
        {authFormType === 'sign-up' && <TermsAndPolicy />}
        <p className="text-size-regular text-align-center text-lineheight-normal">
          {AUTH_FORM_COPY[authFormType].toggleText}{' '}
          <a onClick={toggleFormType}>
            {AUTH_FORM_COPY[authFormType].toggleButtonText}
          </a>
        </p>
      </div>
    </div>
  );
};

export default AuthForm;
