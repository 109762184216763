import React from 'react';
import { getLocalImageUrl } from 'utils/image';
import styles from './SoonaAndTrendSignature.module.sass';

const SoonaAndTrendSignature: React.FC = () => {
  return (
    <div className={styles.signatureWrapper}>
      <div className={styles.logosWrapper}>
        <img
          src={getLocalImageUrl('images/soona-logo.svg')}
          alt="soona-logo"
        />
        <span className={styles.separator} />
        <img
          src={getLocalImageUrl('images/trend-logo-black.svg')}
          alt="trend-logo"
        />
      </div>
      <div>
        Trend is a proud part of the soona family
      </div>
    </div>
  )
};

export default SoonaAndTrendSignature;
