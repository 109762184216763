import isURL from "validator/lib/isURL";

/**
 * Email Validation
 * Source: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
 */

const emailRegex = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
);

export const validateEmail = (input: string) => {
  return emailRegex.test(input);
};

export const validatePasswordOrReturnError = (input: string): true | string => {
  //   if (passwordRegex.test(input)) {
  //     return true;
  //   }
  if (input.length < 6) {
    return `Password must have at least 6 characters`;
  }
  return true;
  //   else if (!hasLowerCaseLetter(input)) {
  //     return `Password must have at least 1 lowercase letter`;
  //   } else if (!hasUpperCaseLetter(input)) {
  //     return `Password must have at least 1 uppercase letter`;
  //   } else if (!hasSpecialCharacter(input)) {
  //     return `Password must have at least 1 special character`;
  //   } else if (!hasDigit(input)) {
  //     return `Password must have at least 1 number`;
  //   }
  //   return "Error";
};

/**
 * Phone Number
 * TODO: Check if we want to use '-'
 */
const phoneRegExp = /^[0-9]{10}/;
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const isValidPhoneNumber = (phone: any): boolean =>
  phoneRegExp.test(phone);

/**
 * Url Validation
 * Export this way to avoid having the entire validator library getting added to the bundle
 */
export { isURL };
