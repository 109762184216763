// ContentExamples
import { EXAMPLE_ASSETS } from 'constants/auth';
import React from 'react';
import { getLocalImageUrl } from 'utils/image';
import styles from './ContentExamples.module.sass';

const ContentExamples: React.FC = () => {
  return (
    <div className={styles.contentExamples}>
      <div className={styles.carousel}>
        {EXAMPLE_ASSETS.map((content) => (
          <ContentExample {...content} key={content.company} />
        ))}
      </div>
    </div>
  );
};

export interface ContentExampleProps {
  imgSrc: string;
  contentType: string;
  company: string;
}

const ContentExample: React.FC<ContentExampleProps> = ({ imgSrc }) => {
  return (
    <div
      className={styles.contentExample}
      style={{ backgroundImage: `url(${getLocalImageUrl(imgSrc)})` }}
    >
      <div className={styles.gradient}>
        <img
          className={styles.logo}
          src={getLocalImageUrl('images/trend-logo-white.svg')}
          alt="trend-logo"
        />
      </div>
    </div>
  );
};

export default ContentExamples;
